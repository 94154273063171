import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Нет такой страницы" />
    <h1>404: Нет такой страницы</h1>
    <Link to="/">Вернуться</Link>
  </>
);

export default NotFoundPage;
